export let colors =  [
              '#1c7af5',
              '#2e84f5',
              "#579BF4",
              "#5e9ff5", 
              "#65a4f7",
              '#68a4f3',
              '#6ca7f5',
              '#70a8f1',
              '#72a9f0',
              '#78acf1',
              '#7db0f1',
              '#80b1f0',
              '#87b5f1',
              '#8db9f3',
              '#90baf1',
              '#9ac0f3',
              '#9ec4f5',
              '#a3c6f5',
              '#a5c7f3',
              '#aecef8',
              '#afcdf5',
              '#b3d0f5',
              '#b6d1f3',
              '#b8d2f1',
              '#bed6f3',
              '#bfd6f1',
              '#c9dff8',
              '#cbdff7',
              '#c8daf0',
              '#d3e5fa',
              '#d9e8fa',
              '#d8e6f7',
              '#dce8f7',
              '#e0eaf7',
              '#e9f0fa',
              '#e7edf5',
              '#f0f4fa'

             ]