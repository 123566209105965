<template>
  <div
    class="customer-analysis-board padding-20"
    id="customer-analysis-board"
    v-loading="dataLoading"
  >
    <div class="putter-operation">
      <el-select
        v-model="params.accountChannelId"
        placeholder="请选择公众号"
        style="width: 140px"
        filterable
      >
        <el-option
          v-for="item in officialList"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <el-button type="board" @click="handleSearch" style="margin-left: 10px"
        >搜索</el-button
      >
      <el-button type="info" @click="handleReset">重置</el-button>
    </div>
    <div class="fields-list">
      <el-card class="box-card">
        <el-row :gutter="10" style="margin-top:3px">
          <div class="field-parts">
            <div
              class="field-part"
              v-for="(item, index) in fieldList"
              :key="index"
            >
              <p class="text-center">{{ item.cname }}</p>
              <p
                class="text-center"
                :class="[
                  ['stayRate', 'liveRate', 'payRate', 'fallRate'].includes(
                    item.name
                  )
                    ? 'orange-color'
                    : '',
                  ['remainBookCurrency'].includes(item.name) ? 'red-color' : ''
                ]"
              >
                {{ item.value }}
              </p>
            </div>
          </div>
        </el-row>
      </el-card>
    </div>
    <el-row :gutter="15">
      <el-col :span="7" class="left-part parts">
        <div class="fans-status">
          <h3>分月粉丝增量情况</h3>
          <div id="fans-status-monthly"></div>
        </div>
        <div class="channel-distribute-box">
          <div class="title">
            <h3>粉丝公众号分布</h3>
            <span v-if="showMoreChannel" @click="checkMore">查看更多</span>
          </div>
          <div id="channel-distribute"></div>
        </div>
        <div class="channel-detail-container">
          <div class="title">
            <span
              class="title-text"
              :style="
                'color:' +
                  subTitleColor +
                  ';font-size:' +
                  subTitleFontSize +
                  ';font-weight:bold'
              "
              >公众号粉丝详情</span
            >
            <el-button-group class="change-page">
              <span class="page-value"> {{ page }}/{{ totalPage }} </span>
              <el-button
                size="mini"
                icon="el-icon-arrow-left"
                style="border-radius:5px 0 0 5px;
                background-color:#c0ccda
                "
                @click="handleTablePageChange('back')"
              ></el-button>
              <el-button
                size="mini"
                style="
                        border-radius: 0 5px 5px 0;
                        background-color: #c0ccda;
                      "
                @click="handleTablePageChange('go')"
                ><i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
          </div>
          <div id="channel-detail">
            <el-table
              :data="accountChannelDetail"
              @sort-change="handleChannelSortChange"
              height="220"
            >
              <el-table-column
                label="公众号"
                min-width="50"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.channelName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="utlCumulateUserCnt"
                label="关注"
                min-width="75"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                label="粉丝"
                prop="utlSubscribeUserCnt"
                min-width="70"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                label="留存率"
                prop="stayRate"
                min-width="70"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.stayRate }}%</div>
                </template>
              </el-table-column>
              <el-table-column
                label="活跃率"
                prop="liveRate"
                min-width="70"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.liveRate }}%</div>
                </template>
              </el-table-column>
              <el-table-column
                label="付费率"
                prop="payRate"
                sortable="custom"
                width="80"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.payRate }}%</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="9" class="middle-part parts">
        <div class="heat-map-container">
          <h3>客户热力图</h3>
          <div class="choose-situation">
            <span
              @click="chooseSituation(1)"
              :class="{ isActive: dataType === 1 }"
              >分布</span
            >
            <span
              @click="chooseSituation(2)"
              :class="{ isActive: dataType === 2 }"
              >活跃</span
            >
            <span
              @click="chooseSituation(3)"
              :class="{ isActive: dataType === 3 }"
              >充值</span
            >
          </div>
          <div id="heat-map"></div>
        </div>
        <div class="fans-pie-container">
          <div id="fansRecentConsumeDays"></div>
          <div id="fansDueSoonDistribution"></div>
        </div>
      </el-col>
      <el-col :span="8" class="left-part parts">
        <div class="pies">
          <div id="fansPriceSensitive"></div>
          <div id="fansBookCurrency"></div>
          <div id="fansSex"></div>
          <div id="fansRfm"></div>
        </div>
        <div class="fans-recharge-container">
          <h3>粉丝充值次数分布</h3>
          <div id="fans-recharge"></div>
        </div>
      </el-col>
    </el-row>
    <fans-channel-detail
      :show.sync="showDialog"
      :info="baseData"
    ></fans-channel-detail>
  </div>
</template>

<script>
import DuPontPicture from "@/components/duPontPicture";
import SelectPlatformChannel from "@/components/SelectPlatformChannel.vue";
import FansChannelDetail from "./fans-channel-detail.vue";
import {
  formatThousandsnoz,
  formatThousandsOne,
  formatTime,
  getPreMonth,
  getPresentMonth,
  getRecentlyMonth
} from "@/assets/js/utils.js";
import { getBaseData, getHeatMapData, getFansInc } from "@/api/monitor.js";
import { mapGetters, mapActions } from "vuex";
import echarts from "echarts";
import "@/assets/js/china.js";
import { colors } from "./../board/mockData.js";

export default {
  data() {
    return {
      params: {},
      fieldList: [],
      baseData: null,
      fansInc: null,
      heatMapData: null,
      fansRecentConsumeDays: null,
      fansDueSoonDistribution: null,
      fansPriceSensitive: null,
      fansBookCurrency: null,
      fansSex: null,
      fansRfm: null,
      fansRecharge: null,
      subTitleColor: "#969696",
      subTitleFontSize: "12px",
      dataLoading: false,
      showMoreChannel: false,
      showDialog: false,
      accountChannelDetail: null,
      copyAccountChannelDetail: null,
      dataType: 1,
      page: 1,
      totalPage: 1
    };
  },
  mounted() {
    this.initData("created");
    window.onresize = () => {
      this.fansStatus && this.fansStatus.resize();
      this.fansAccountChannel && this.fansAccountChannel.resize();
      this.heatMap && this.heatMap.resize();
      this.fansRecentConsumeDays && this.fansRecentConsumeDays.resize();
      this.fansDueSoonDistribution && this.fansDueSoonDistribution.resize();
      this.fansPriceSensitive && this.fansPriceSensitive.resize();
      this.fansBookCurrency && this.fansBookCurrency.resize();
      this.fansSex && this.fansSex.resize();
      this.fansRfm && this.fansRfm.resize();
      this.fansRecharge && this.fansRecharge.resize();
    };
    this.$on("hook:beforeDestroy", () => {
      window.onresize = null;
    });
  },
  computed: {
    ...mapGetters(["officialList"])
  },
  methods: {
    async initData(flag) {
      this.dataLoading = true;
      await this.getData(flag);
      await this.initGraph();
      this.initGraphEvent()
      this.dataLoading = false;
    },
    async getData(flag) {
      if (flag === "created") {
        const tmpIndex = this.$store.state.routerHistory.findIndex(
          item => item.path === this.$route.path
        );
        if (tmpIndex !== -1) {
          this.params = this.$store.state.routerHistory[tmpIndex].params;
        }
      }
      let [baseData, fansInc, heatMapData] = await Promise.all([
        getBaseData({
          accountChannelId: this.params.accountChannelId
        }),
        getFansInc({
          accountChannelId: this.params.accountChannelId
        }),
        getHeatMapData({
          accountChannelId: this.params.accountChannelId,
          dataType: this.dataType
        })
      ]);
      let { accountChannelDetail, index, fansBookCurrency } = baseData;
      this.copyAccountChannelDetail = JSON.parse(
        JSON.stringify(accountChannelDetail)
      );
      this.totalPage = Math.ceil(this.copyAccountChannelDetail.length / 20);
      this.accountChannelDetail = this.copyAccountChannelDetail.slice(
        20 * (this.page - 1),
        this.page * 20
      );
      this.baseData = baseData;
      this.fansInc = fansInc;
      let data = heatMapData.map(item => {
        return {
          name: item.province,
          value: Number(item.userCnt.replace(/,/g, "")),
          showValue: formatThousandsnoz(Number(item.userCnt.replace(/,/g, "")))
        };
      });
      data = data.map(item => {
        if (item.name === "北京") {
          return {
            ...item,
            itemStyle: {
              normal: {
                areaColor: "#D9EDE1"
              }
            }
          };
        }
        return item;
      });
      this.heatMapData = JSON.parse(JSON.stringify(data));

      this.fieldList = [
        {
          name: "culSub",
          cname: "累计关注",
          value: `${index.culSub}万`
        },
        {
          name: "subFans",
          cname: "现关粉丝",
          value: `${index.subFans}万`
        },
        {
          name: "liveFans",
          cname: "活跃粉丝",
          value: `${index.liveFans}万`
        },
        {
          name: "payFans",
          cname: "付费粉丝",
          value: `${index.payFans}万`
        },
        {
          name: "remainBookCurrency",
          cname: "书币剩余",
          value: `${index.remainBookCurrency}万`
        },
        {
          name: "stayRate",
          cname: "留存率",
          value: `${index.stayRate}%`
        },
        {
          name: "liveRate",
          cname: "活跃率",
          value: `${index.liveRate}%`
        },
        {
          name: "payRate",
          cname: "付费率",
          value: `${index.payRate}%`
        },
        {
          name: "fallRate",
          cname: "流失率",
          value: `${index.fallRate}%`
        }
      ];
    },
    initGraph() {
      // 初始化 粉丝新增情况
      this.initFansInc();
      // 初始化粉丝分布
      this.initSqGraph();
      // 初始化热力图
      this.initHeatMap();
      // 初始化饼图
      this.initPieGraph();
      // 初始化 柱状图
      this.initLineGraph();
    },
    initFansInc() {
      const option = {
        legend: {
          icon: "circle",
          data: ["增加粉丝", "流失粉丝"],
          right: "40%",
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: "axis",
          formatter: params => {
            return `<div>
            <p>${params[0].seriesName}:${formatThousandsnoz(
              params[0].value
            )}</p>
            <p>${params[1].seriesName}:${formatThousandsnoz(
              params[1].value
            )}</p>
            </div>`;
          },
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 8
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6" //更改坐标轴颜色
            }
          },
          data: this.fansInc.date
        },
        yAxis: {
          type: "value",
          show: false
        },
        grid: {
          left: "1%"
          // right: "6%",
          // bottom: "5%",
          // containLabel: true
        },
        series: [
          {
            data: this.fansInc.graph[0].value,
            name: "增加粉丝",
            type: "line",
            areaStyle: {},
            lineStyle: {
              color: "#579BF4"
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#579BF4"
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4"
                  }
                ]
              }
            },
            symbol: "none"
          },
          {
            data: this.fansInc.graph[1].value,
            name: "流失粉丝",
            type: "line",
            areaStyle: {},
            symbol: "none",
            lineStyle: {
              color: "#FFB85E"
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FFB85E"
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4"
                  }
                ]
              }
            }
          }
        ]
      };
      if (!this.fansStaus) {
        this.fansStatus = echarts.init(
          document.getElementById("fans-status-monthly")
        );
      }
      this.fansStatus.setOption(option);
    },
    initSqGraph() {
      let { fansAccountChannel } = this.baseData;
      fansAccountChannel.forEach(item => {
        item.name = item.channelName;
        item.value = Number(item.utlCumulateUserCnt.split(",").join(""));
      });
      fansAccountChannel = fansAccountChannel.sort((a,b)=>{
        return a.value-b.value
      }).reverse()
      let len = fansAccountChannel.length;
      this.showMoreChannel = len > 20 ? true : false;
      const colors = [];
      const customColors = [
        "#81b6fa",
        "#6273E0",
        "#579BF4",
        "#AB74E9",
        "#FF9163",
        "#FFB85E",
        "#00BF8A",
        "#FB817F"
      ];
      for (let i = 0; i < Math.ceil(len / customColors.length); i++) {
        colors.push(...customColors);
      }
      const options = {
        tooltip: {
          formatter: info => {
            return `
            <p>公众号:${info.name}</p>
            <p>粉丝数:${formatThousandsnoz(info.value)}</p>`;
          }
        },
        color: colors,
        legend: {
          show: true
        },
        series: [
          {
            left: "left",
            width: "100%",
            breadcrumb: {
              show: false
            },
            top: "10%",
            bottom: "0",
            roam: false,
            nodeClick: false,
            type: "treemap",
            label: {
              show: true,
              formatter: "{b}"
            },
            data: fansAccountChannel.slice(0, 20)
          }
        ]
      };
      if (this.fansAccountChannel) {
        this.fansAccountChannel.dispose();
      }
      this.fansAccountChannel = echarts.init(
        document.querySelector("#channel-distribute")
      );
      this.fansAccountChannel.setOption(options);
    },
    initHeatMap() {
      let data = this.heatMapData.sort((a, b) => a.value - b.value).reverse();
      let color = JSON.parse(JSON.stringify(colors)).reverse();
      let maxNum = Math.max(...data.map(item => item.value));
      let minNum = Math.min(...data.map(item => item.value));
      const options = {
        backgroundColor: "#fff", //设置地图外的背景颜色
        tooltip: {
          trigger: "item",
          formatter: info => {
            return `<div class='text'>
              <p>${info.data ? info.data.name : "暂无数据"}</p>
              <p>${info.data ? info.data.showValue : ""}</p>
              </div></div>`;
          }
        },
        geo: {
          // 地图组件配置
          map: "china", // 选择地图
          label: {
            // 绑定文本的设置
            normal: {
              // 初始状态配置
              fontSize: 13
            },
            emphasis: {
              // 选中板块的配置，api与normal相同
              show: true, // 是否显示文本
              color: "#fff"
            },
            formatter: "{a}ok" // 标签显示内容,字符创与函数两种格式,附带多种变量用法↓
            // https://echarts.apache.org/zh/option.html#geo.label.formatter
          },
          roam: false, //可缩放和平移
          itemStyle: {
            // 选中板块配置
            normal: {
              // 初始样式
              areaColor: "#323c48", // 地图颜色
              borderColor: "#020", //线条颜色
              borderType: "dotted", //边框样式
              opacity: ".8"
            },
            emphasis: {
              //选中后
              areaColor: "#2a333d" //鼠标移入颜色
            }
          },
          selectedMode: "single" //点击板块会高亮选中板块，默认多选
          // regions: 'regions', // 在地图中对特定的区域配置样式,灵活操作
        },
        series: [
          {
            name: "中国",
            type: "map",
            mapType: "china",
            selectedMode: "single",
            roam: false,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            data
          }
        ],
        visualMap: {
          min: minNum,
          max: maxNum,
          text: ["High", "Low"],
          realtime: false,
          calculable: true,
          left: "left",
          itemHeight: 100,
          textStyle: {
            fontSize: 10,
            color: "#969696"
          },
          inRange: {
            color
          }
        }
      };
      // if(!this.heatMap){
      //   this.heatMap = echarts.init(document.querySelector("#heat-map"));
      // }
      if (this.heatMap) {
        this.heatMap.dispose();
      }
      this.heatMap = echarts.init(document.querySelector("#heat-map"));
      this.heatMap.setOption(options);
    },
    initPieGraph() {
      let {
        fansRecentConsumeDays,
        fansDueSoonDistribution,
        fansPriceSensitive, // 粉丝敏感度
        fansBookCurrency, // 剩余书币
        fansSex, // 性别分布
        fansRfm // 粉丝rfm
      } = this.baseData;
      fansRecentConsumeDays = fansRecentConsumeDays
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();
      fansDueSoonDistribution = fansDueSoonDistribution
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();
      fansPriceSensitive = fansPriceSensitive
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();
      fansBookCurrency = fansBookCurrency
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();
      fansSex = fansSex
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();

      fansRfm = fansRfm
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();
      // 粉丝消费天数分布
      const colors = [
        {
          c1: "#579BF4",
          c2: "#579BF4"
        },
        {
          c1: "#FFB85E",
          c2: "#FFB85E"
        },
        {
          c1: "#81b6fa",
          c2: "#81b6fa"
        },
        {
          c1: "#AB74E9",
          c2: "#AB74E9"
        },
        {
          c1: "#a1c4fd",
          c2: "#c2e9fb"
        },
        {
          c1: "#FB817F",
          c2: "#FB817F"
        },
        {
          c1: "#00bf8a",
          c2: "#00bf8a"
        },
        {
          c1: "#fd5d5a",
          c2: "#fd5d5a"
        }
      ];
      let dataCollection = [
        {
          fieldName: "fansRecentConsumeDays",
          data: fansRecentConsumeDays,
          color: colors,
          title: "粉丝最近消费天数分布"
        },
        {
          fieldName: "fansDueSoonDistribution",
          data: fansDueSoonDistribution,
          color: colors,
          title: "粉丝活跃即将到期分布"
        },
        {
          fieldName: "fansPriceSensitive",
          data: fansPriceSensitive,
          color: colors,
          title: "粉丝价格敏感分布"
        },
        {
          fieldName: "fansBookCurrency",
          data: fansBookCurrency,
          color: colors,
          title: "粉丝书币剩余分布"
        },
        {
          fieldName: "fansSex",
          data: fansSex,
          color: colors,
          title: "粉丝性别分布"
        },
        {
          fieldName: "fansRfm",
          data: fansRfm,
          color: colors,
          title: "粉丝RFM画像分布"
        }
      ];

      dataCollection.forEach(item => {
        const options = {
          title: {
            text: item.title,
            textStyle: {
              fontSize: 12,
              color: "#969696"
            }
          },
          tooltip: {
            trigger: "item",
            formatter: params => {
              return `<div><p>${params.name}</p>
                              <p>人数:${formatThousandsnoz(params.value)}</p>
                              <p>占比:${params.percent}%</p>
                            </div>`;
            }
          },
          grid: {
            left: '0',
            // top: '15%',
            right: 0,
            bottom: 0
          },
          series: [
            {
              type: "pie",
              radius: "70%",
              emphasis: {
                label: {
                  show: true,
                  fontWeight: "bold",
                  fontSize: "12"
                }
              },

              // selectedMode:'single',
              label: {
                fontSize: 10,
                color: "#969696",
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "12",
                    fontWeight: "bold"
                  }
                }
              },
              labelLine: {
                show: true,
                showAbove: true,
                length2: 5,
                length: 8
              },
               width:'100%',
              itemStyle: {
                borderWidth: 1,
                borderColor: "#ffffff",
                color: params => {
                  const colorList = [];
                  const len = item.data.length;
                  for (let i = 0; i < Math.ceil(len / item.color.length); i++) {
                    colorList.push(...item.color);
                  }
                  return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: colorList[params.dataIndex].c1
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c2
                    }
                  ]);
                }
              },
              data: item.data
            }
          ]
        };
        this[`${item.fieldName}`] = echarts
          .init(document.querySelector(`#${item.fieldName}`))
          .setOption(options);
      });
    },
    initLineGraph() {
      let { fansRechargeNum } = this.baseData;
      fansRechargeNum = fansRechargeNum
        .map(item => {
          return {
            name: item.type,
            value: item.userCnt
          };
        })
        .sort((a, b) => a.value - b.value);
     let maxNum = Math.max(...fansRechargeNum.map(item=>Number(item.value)))
     let minNum = Math.min(...fansRechargeNum.map(item=>Number(item.value))) 
      let options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            return `${params[0].axisValue}:${formatThousandsnoz(
              params[0].data
            )}人`;
          }
        },
        color: "#579BF4",
        grid: {
          left: "0",
          right: "0",
          bottom: "0%",
          top: "0%",
          containLabel: true
        },
        xAxis: {
          min:0,
          max:maxNum,
          type: "value",
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          
          type: "category",
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#969696"
            }
          },
           axisLabel: {
            fontSize: 8,
          },

          data: fansRechargeNum.map(item => item.name)
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              normal: {
                barBorderRadius: [0, 5, 5, 0],
                label: {
                  show: true, //开启显示
                  position: "insideRight", //在上方显示
                  formatter: params => {
                    return `${formatThousandsnoz(params.value)}人`;
                  },
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 12
                  }
                }
              }
            },
            data: fansRechargeNum.map(item => item.value)
          }
        ]
      };
      this.fansRecharge = echarts
        .init(document.querySelector("#fans-recharge"))
        .setOption(options);
    },
    initGraphEvent(){
       this.fansAccountChannel.on('click','series',params=>{
         this.params.accountChannelId = params.data.accountChannelId
         this.initData()
       })
    },
    checkMore() {
      this.showDialog = true;
    },
    handleTablePageChange(status) {
      if (status === "go") {
        this.page++;
      }
      if (status === "back") {
        this.page--;
      }
      this.accountChannelDetail = this.copyAccountChannelDetail.slice(
        20 * (this.page - 1),
        this.page * 20
      );
    },
    handleChannelSortChange({ order, prop }) {
      let data = this.copyAccountChannelDetail.sort(
        (a, b) =>
          Number(a[`${prop}`].replace(/,/g, "")) -
          Number(b[`${prop}`].replace(/,/g, ""))
      );
      this.page = 1;
      if (order === "descending") {
        this.accountChannelDetail = data.slice(
          (this.page - 1) * 20,
          this.page * 20
        );
      } else {
        this.accountChannelDetail = data
          .reverse()
          .slice((this.page - 1) * 20, this.page * 20);
      }
    },
    handleSearch() {
      this.initData();
    },
    chooseSituation(dataType) {
      this.dataType = dataType;
      getHeatMapData({
        accountChannelId: this.params.accountChannelId,
        dataType: this.dataType
      }).then(heatMapData => {
        let data = heatMapData.map(item => {
          return {
            name: item.province,
            value: Number(item.userCnt.replace(/,/g, "")),
            showValue: formatThousandsnoz(
              Number(item.userCnt.replace(/,/g, ""))
            )
          };
        });
        data = data.map(item => {
          if (item.name === "北京") {
            return {
              ...item,
              itemStyle: {
                normal: {
                  areaColor: "#D9EDE1"
                }
              }
            };
          }
          return item;
        });
        this.heatMapData = JSON.parse(JSON.stringify(data));
        this.initHeatMap();
      });
    },
    handleReset() {
      this.params.accountChannelId = null;
      this.initData();
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory"
    })
  },
  components: {
    SelectPlatformChannel,
    FansChannelDetail
  }
};
</script>
<style lang="scss" scoped>
.orange-color {
  color: #ffa700 !important;
}
.red-color {
  color: #fb817f !important;
}
/deep/ .el-table {
  color: rgb(150, 150, 150) !important;
  overflow: auto;
}
.el-card {
  border: none;
  /deep/ .el-card__header {
    padding: 12px 0;
  }
  /deep/ .el-card__body {
    padding: 0;
  }
}
.isActive {
  color: #579bf4 !important;
}
div[class$="container"] {
  margin-top: 20px;
}
.customer-analysis-board {
  background: #fff;

  h3 {
    font-size: 12px;
    color: #969696;
  }
  .putter-operation {
    margin-bottom: 20px;
  }
  .fans-status {
    #fans-status-monthly {
      margin-top: 20px;
      width: 100%;
      height: 200px;
    }
  }
  .channel-distribute-box {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        cursor: pointer;
        color: #579bf4;
        font-size: 12px;
        font-weight: bold;
      }
    }
    #channel-distribute {
      width: 100%;
      height: 150px;
    }
  }
  .channel-detail-container {
    margin-top: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .change-page {
        margin-left: 20px;
        /deep/ .el-button--mini {
          padding: 0px 3px !important;
          .el-icon--right {
            margin: 0 !important;
          }
        }
        .page-value {
          margin-right: 5px;
          font-size: 12px;
          text-align: center;
          margin-top: 1px;
          color: #8492a6;
        }
      }
    }
  }
  .field-parts {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 20px;
    .field-part {
      > p:nth-of-type(1) {
        margin-bottom: 1.5vh;
        font-size: 12px;
        color: #969696;
      }
      > p:nth-of-type(2) {
        font-weight: bold;
        font-size: 21px;
      }
    }
    .field-part-payback {
      margin-right: 2vw;
      > p:nth-of-type(2) {
        color: #00ccff;
      }
    }
  }
  .heat-map-container {
    position: relative;
    h3 {
      text-align: center;
    }
    .choose-situation {
      position: absolute;
      z-index: 999;
      top: 15%;
      span {
        font-size: 12px;
        font-weight: bold;
        margin-right: 10px;
        color: #969696;
        cursor: pointer;
      }
    }
    #heat-map {
      height: 400px;
      width: 100%;
    }
  }
  .fans-pie-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #fansRecentConsumeDays {
      height: 220px;
      width: 50%;
    }
    #fansDueSoonDistribution {
      flex-grow: 1;
      // width:60%;
      height: 220px;
    }
  }

  .pies {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    >div{
      margin-bottom:18px;
    }
    #fansPriceSensitive {
      width: 50%;
      height: 200px;
    }
    #fansBookCurrency {
      width: 50%;
      height: 200px;
    }
    #fansSex {
      width: 50%;
      height: 200px;
    }
    #fansRfm {
      width: 50%;
      height: 200px;
    }
  }

  .fans-recharge-container {
    width: 100%;
    #fans-recharge {
      width: 100%;
      height: 180px;
    }
  }
  /deep/ td {
    padding: 5px !important;
  }
  /deep/ .is-leaf {
    padding: 0 !important;
  }
  /deep/ .has-gutter tr {
    height: 30px !important;
  }
  /deep/ tr td {
    font-size: 12px !important;
  }
  /deep/ thead tr th {
    font-size: 12px !important;
  }
  /deep/ th > .cell {
    padding-left: 2px;
    padding-right: 2px;
  }
  color: #f0f4fa;
}
</style>
