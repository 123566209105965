<template>
  <el-dialog
    title="粉丝公众号分布"
    width="80%"
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="fans-channel-detail">
      <div class="operation">
        <el-select
          v-model="currentOfficial"
          multiple
          placeholder="请选择公众号"
          clearable
          @clear="handleResetData"
          style="width: 500px;margin-right:10px"
          filterable
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.channelName"
            :value="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
        <el-button type="info" @click="handleResetData">重置</el-button>
      </div>
      <div class="main-container">
        <div id="fans-channel-container"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import echarts from "echarts";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["info", "show"],
  data() {
    return {
      currentOfficial: [],
      copyFansAccountChannel: null,
      len: 0
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        item =>
          (item.platform === 1 ||
            item.platform === 0 ||
            item.platform === 3 ||
            item.platform === 4 ||
            item.platform === 6) &&
          item.status === 1
      );
    },
    ...mapGetters(["officialList"])
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.$nextTick(() => {
            this.initEvent();
            this.formatData();
            this.renderData();
          });
        }
      }
    }
  },
  mounted() {},
  methods: {
    initEvent() {
      window.onresize = () => {
        this.fansAccountChannel && this.fansAccountChannel.resize();
      };
      this.$on("hook:beforeDestroy", () => {
        window.onresize = null;
      });
    },
    formatData() {
      let { fansAccountChannel } = this.info;
      fansAccountChannel.forEach(item => {
        item.name = item.channelName;
        item.value = Number(item.utlCumulateUserCnt.split(",").join(""));
      });
      this.copyFansAccountChannel = JSON.parse(
        JSON.stringify(fansAccountChannel)
      );
      this.len = fansAccountChannel.length;
    },
    renderData() {
      const colors = [];
      const customColors = [
        "#81b6fa",
        "#6273E0",
        "#3F80FF",
        "#AB74E9",
        "#FF9163",
        "#FFB85E",
        "#00BF8A",
        "#FB817F"
      ];
      for (let i = 0; i < Math.ceil(this.len / customColors.length); i++) {
        colors.push(...customColors);
      }
      const options = {
        tooltip: {
          formatter: info => {
            return `
            <p>公众号:${info.name}</p>
            <p>粉丝数:${info.value}</p>`;
          }
        },
        color: colors,
        legend: {
          show: true
        },
        series: [
          {
            left: "left",
            width: "100%",
            breadcrumb: {
              show: false
            },
            top: "10%",
            bottom: "0",
            roam: false,
            nodeClick: false,
            type: "treemap",
            label: {
              show: true,
              formatter: "{b}"
            },
            data: this.copyFansAccountChannel
          }
        ]
      };
      if (this.fansAccountChannel) {
        this.fansAccountChannel.dispose();
      }
      this.fansAccountChannel = echarts.init(
        document.querySelector("#fans-channel-container")
      );
      this.fansAccountChannel.setOption(options);
    },
    handleSearch() {
      if (
        !this.currentOfficial ||
        (Array.isArray(this.currentOfficial) &&
          this.currentOfficial.length === 0)
      ) {
        this.formatData();
        this.renderData();
        return;
      }
      let { fansAccountChannel } = this.info;
      fansAccountChannel.forEach(item => {
        item.name = item.channelName;
        item.value = Number(item.utlCumulateUserCnt.split(",").join(""));
      });
      let arr = this.channelList
        .filter(item => {
          return this.currentOfficial.includes(item.id);
        })
        .map(item => item.channelName);
      this.copyFansAccountChannel = fansAccountChannel.filter(item =>
        arr.includes(item.name)
      );
      this.len = this.copyFansAccountChannel.length;
      this.renderData();
    },
    handleClose() {
      this.currentOfficial = null;

      this.$emit("update:show", false);
    },
    handleResetData() {
      this.currentOfficial = []
      this.formatData();
      this.renderData();
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.fans-channel-detail {
  .main-container {
    width: 100%;
    #fans-channel-container {
      //  width:1200px;
      height: 600px;
    }
  }
}
</style>
