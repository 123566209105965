import { render, staticRenderFns } from "./fans-channel-detail.vue?vue&type=template&id=130c1f2b&scoped=true&"
import script from "./fans-channel-detail.vue?vue&type=script&lang=js&"
export * from "./fans-channel-detail.vue?vue&type=script&lang=js&"
import style0 from "./fans-channel-detail.vue?vue&type=style&index=0&id=130c1f2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130c1f2b",
  null
  
)

export default component.exports